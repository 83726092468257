/*
 * Custom
 */

$(function(){


  $(window).on('ajaxErrorMessage', function(event, message){

      // This can be any custom JavaScript you want
      //alert('Something bad happened, mate, here it is: ' + message);

      // This will stop the default alert() message
      //event.preventDefault();

  })

});